import React from "react";
import styled from "styled-components";
import SimpleSection from "../../../components/work/simple-section";
import WorkingTogetherSection from "../../../components/work/working-together-section";
import { PageElement } from "../../../graphql/page-common";
import { getElementImage, getElementText, getElementVideo } from "../../../utilities/pimcore-data-transformer";
import WorkVideoElement from "../work-video-element";

interface WorkingSectionProps {
  headline: string;
  intro: Array<PageElement>;
  content: Array<PageElement>;
}

const WorkingSection: React.FC<WorkingSectionProps> = ({ headline, intro, content }) => {
  return (
    <>
      <VideoWrapper>
        <WorkVideoElement
          video={getElementVideo(intro[0])}
          image={getElementImage(intro[0])}
          content={getElementText(intro[1])}
          padding="tablet:pr-28"
        />
      </VideoWrapper>
      <SectionWrapper>
        <WorkingTogetherSection
          headline={headline}
          subHeadline={getElementText(content[0])}
          image={getElementImage(content[1])}
          content={getElementText(content[2])}
          // quote={{
          //   message:
          //     "In my role I get to see everyone’s point of view. I need to know what everyone does to create the best governance and management practices.",
          //   name: "Camila",
          //   position: "Head of Data Governance",
          //   modifier: "left-4 bottom-4 tablet:bottom-[26rem] tablet:left-[32rem]",
          //   left: true,
          // }}
          marginBottom="tablet:mb-6"
          padding="tablet:pr-20 "
        />
        <SimpleSection
          headline={getElementText(content[3])}
          image={getElementImage(content[4])}
          content={getElementText(content[5])}
          marginTop="tablet:mt-24"
          padding="tablet:pr-12 "
          left
        />
        <SimpleSection
          headline={getElementText(content[6])}
          image={getElementImage(content[7])}
          content={getElementText(content[8])}
          marginTop="tablet:mt-20"
          padding="tablet:pr-[3.25rem]"
        />
        <SimpleSection
          headline={getElementText(content[9])}
          image={getElementImage(content[10])}
          content={getElementText(content[11])}
          marginTop="tablet:mt-24"
          padding="tablet:pr-12"
          left
        />
        <SimpleSection
          headline={getElementText(content[12])}
          image={getElementImage(content[13])}
          content={getElementText(content[14])}
          padding="tablet:pr-14 "
          marginTop="tablet:mt-0"
        />
      </SectionWrapper>
    </>
  );
};

export default WorkingSection;

const VideoWrapper = styled.div.attrs({ className: "-mt-64 tablet:-mt-72 relative z-20 scrollspy" })``;
const SectionWrapper = styled.div.attrs({ className: "scrollspy" })``;
