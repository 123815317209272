import React from "react";
import styled from "styled-components";
import { ImageRightWrapper } from "../../../components/images/images";
import { PimcoreImage } from "../../../components/pimcore-image";
import { Headline3, Paragraph } from "../../../components/typography";
import ExampleHeaderSection from "../../../components/work/example-header-section";
import SimpleSection from "../../../components/work/simple-section";
import { PageElement } from "../../../graphql/page-common";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { theme } from "../../../styles/styles";
import { onScrollAnimationDesktop } from "../../../utilities/animation-helper";
import { getElementImage, getElementText } from "../../../utilities/pimcore-data-transformer";

interface ExamplesSectionProps {
  headline: string;
  content: Array<PageElement>;
}

const ExampleSection: React.FC<ExamplesSectionProps> = ({ headline, content }) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(wrapper.current, [wrapper.current?.children || null], matchMobile);

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <ScrollWrapper>
      <ExampleHeaderSection
        headline={headline}
        subHeadline={getElementText(content[0])}
        primaryImage={getElementImage(content[1])}
        content={getElementText(content[2])}
        secondaryImage={getElementImage(content[3])}
        // quote={{
        //   message:
        //     "Data is at the core of our digitalisation strategy. It’s vital to treat it as an asset, to understand its value and its impact for the company.",
        //   name: "Camila",
        //   position: "Head of Data Governance",
        //   modifier: "hidden tablet:flex tablet:right-[22rem] tablet:bottom-60",
        // }}
        bigger
        marginTop="tablet:mt-12"
        prParagraph="8"
      />
      <Grid ref={wrapper}>
        <MediaCell1>
          <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[4]) }} />
        </MediaCell1>
        <MediaCell2>
          <ImageRightWrapper>
            <PimcoreImage image={getElementImage(content[5])} modifier="w-full rounded-lg" withAspectRatio />
          </ImageRightWrapper>
        </MediaCell2>
        <MediaCell3>
          <ParagraphWrapper>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
          </ParagraphWrapper>
        </MediaCell3>
      </Grid>
      <SimpleSection
        headline={getElementText(content[7])}
        image={getElementImage(content[8])}
        content={getElementText(content[9])}
        left
        padding="tablet:pr-4"
        marginTop="tablet:mt-8"
      />
    </ScrollWrapper>
  );
};

export default ExampleSection;

const ScrollWrapper = styled.div.attrs({ className: "scrollspy" })``;
const Grid = styled.div.attrs({
  className: "tablet:grid gap-4 px-5 tablet:grid-cols-12 mb-8 tablet:mb-32",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(3, auto);
  }
`;
const ParagraphWrapper = styled.div.attrs({
  className: "tablet:pr-16",
})``;
const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-3 tablet:row-start-1 tablet:row-span-1 tablet:pt-5",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-6 tablet:col-span-6 tablet:row-start-1 tablet:row-span-3 mb-8 tablet:mb-0x",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1",
})``;
